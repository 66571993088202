import cacheApi from '@/core/cacheApi'

import { handleError } from '@/helpers/handleError'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import type { Option } from '@/types/option'
import type { QueryParameters } from '@/types'
import { useLanguage } from '@/composables/language'
const { mainLang } = useLanguage()

export async function getAttributeOptions(
  id: number,
  { page, itemsPerPage, search }: QueryParameters
): Promise<ApiResponse> {
  try {
    const {
      data,
      status: _status,
      headers
    } = await cacheApi.get(`/wp-json/wc/v3/products/attributes/${id}/terms`, {
      params: {
        page: page || 1,
        per_page: itemsPerPage || 10,
        search: search || null,
        lang: mainLang
      }
    })

    const options = data.sort((a: Option, b: Option) => a.menu_order - b.menu_order)
    return {
      success: true,
      data: { options, total_items: headers['x-wp-total'] as number }
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
