import { defineStore } from 'pinia'
import { getAllAttributes } from '@/api/attributes/getAllAttributes'
import { deleteAttribute } from '@/api/attributes/deleteAttribute'
import { createAttribute } from '@/api/attributes/createAttribute'
import { getAttributeOptions } from '@/api/attributes/options/getAttributeOptions'
import {
  batchDeleteAttributeOptions,
  deleteAttributeOption
} from '@/api/attributes/options/deleteAttributeOption'
import type { Attribute } from '@/types/attribute'
import type { Option } from '@/types/option'
import type { QueryParameters } from '@/types'

import { handleError } from '@/helpers/handleError'

interface AttributeStore {
  attributes: Attribute[]
  loading: boolean
  selectedAttributeId: number | null
  options: Option[]
  optionsLength: number
  currentAttribute: Attribute | null
  optionsLoading: boolean
}

export const useAttributesStore = defineStore('attributes', {
  state: (): AttributeStore => ({
    attributes: [],
    loading: true,
    selectedAttributeId: null,
    options: [],
    optionsLength: 0,
    currentAttribute: null,
    optionsLoading: true
  }),

  actions: {
    async getAttributes() {
      const storedApiValue = localStorage.getItem('newApiSwitchValue')
      const isNewAPI =
        storedApiValue !== null
          ? JSON.parse(storedApiValue)
          : import.meta.env.VITE_NEW_API === 'true'
      if (isNewAPI) {
        await this.getAttributesNew()
      } else {
        await this.getAttributesOld()
      }
    },
    async getAttributesNew() {
      this.loading = true
      this.optionsLoading = true
      if (this.attributes.length > 0) {
        this.loading = false
        return
      }
      const { data, success, error } = await getAllAttributes()

      if (success) {
        this.attributes = data
      } else {
        handleError(error)
      }

      this.loading = false
    },
    async getAttributesOld() {
      this.loading = true
      this.optionsLoading = true
      if (this.attributes.length > 0) {
        await this.getAllAttributesOptions()
        this.loading = false
        return
      }
      const { data, success, error } = await getAllAttributes()
      if (success) {
        this.attributes = data
      } else {
        handleError(error)
      }
      await this.getAllAttributesOptions()
      this.loading = false
    },

    async getAllAttributesOptions() {
      this.optionsLoading = true
      const fetchOptionsPromises = this.attributes.map(async (attribute) => {
        if (attribute.options) {
          return
        }
        const { data, success, error } = await getAttributeOptions(attribute.id, {
          page: 1,
          itemsPerPage: 100,
          search: undefined
        })
        if (success) {
          attribute.options = data.options
        } else {
          handleError(error)
        }
      })

      // Wait for all promises to resolve
      await Promise.all(fetchOptionsPromises)

      // Set the loading status to false once all operations are completed
      this.optionsLoading = false
    },
    async deleteAttribute(id: number) {
      const { success, error } = await deleteAttribute(id)
      if (success) {
        this.attributes = this.attributes.filter((attribute: Attribute) => attribute.id !== id)
      }
      return {
        success,
        error
      }
    },
    async createAttribute(createData: {
      name: string
      type: string
      translation_strings?: { [key: string]: string }
      slug: string
    }) {
      const { success, data } = await createAttribute(createData)

      if (!success) {
        return {
          success: false
        }
      }
      this.attributes.unshift(data)
      return {
        success: true
      }
    },

    updateAttribute(id: number, data: any) {
      this.attributes = this.attributes.map((attribute: Attribute) => {
        if (attribute.id == id) {
          attribute = data
        }
        return attribute
      })
    },

    async getAttributesOptions(options: QueryParameters) {
      this.loading = true
      const res = await getAttributeOptions(this.selectedAttributeId!, options)
      if (res.success) {
        this.options = res.data.options
        this.optionsLength = res.data.total_items
        this.loading = false
      }
    },

    async deleteAttributeOption(id: number, attribute_id: string | number) {
      const { success, error } = await deleteAttributeOption(id, attribute_id as string)
      if (success) {
        this.options = this.options.filter((option: Option) => option.id !== id)

        this.attributes = this.attributes.map((attribute: Attribute) => {
          if (attribute.id == attribute_id) {
            attribute.options = attribute.options?.filter((option: Option) => option.id !== id)
          }
          return attribute
        })
      }

      return {
        success,
        error
      }
    },
    async batchDeleteAttributeOptions(ids: number[], attribute_id: string | number) {
      const { success, error } = await batchDeleteAttributeOptions(ids, attribute_id as string)
      if (success) {
        for (const id of ids) {
          this.options = this.options.filter((option: Option) => option.id !== id)
          this.attributes = this.attributes.map((attribute: Attribute) => {
            if (attribute.id == attribute_id) {
              attribute.options = attribute.options?.filter((option: Option) => option.id !== id)
            }
            return attribute
          })
        }
      }
      return {
        success,
        error
      }
    },
    async updateAttributeOption(
      option_id: string | number,
      payload: any,
      attribute_id: string | number
    ) {
      this.options = this.options.map((option: Option) => {
        if (option.id === Number(option_id)) {
          option = payload
        }
        return option
      })

      this.attributes = this.attributes.map((attribute: Attribute) => {
        if (attribute.id + '' == attribute_id + '') {
          attribute.options = attribute.options?.map((option: Option) => {
            if (option.id === Number(option_id)) {
              option = payload
            }
            return option
          })
        }
        return attribute
      })

      return {
        success: true,
        error: false
      }
    },
    setCurrentAttribute(attribute: Attribute) {
      this.currentAttribute = attribute
      this.selectedAttributeId = attribute.id
    },
    addAttributeOption(attributeId: string | number, option: Option) {
      this.options.unshift(option)

      this.attributes = this.attributes.map((attribute: Attribute) => {
        if (attribute.id == attributeId) {
          attribute.options?.unshift(option)
        }
        return attribute
      })
    }
  }
})
