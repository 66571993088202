import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toast } from 'vue3-toastify'
import { toastDone } from '../util/toastHelper'

export async function updateOrders(payload: any): Promise<ApiResponse> {
  try {
    const toastId = toast.loading('جاري تحديث الطلب / الطلبات')
    const { status: _status } = await api.post(`/wp-json/wc/v3/orders/batch`, payload)

    if (payload.update) {
      if (payload?.update[0]?.status == 'trash') toastDone(toastId, 'تم حذف الطلب / الطلبات بنجاح')
      else toastDone(toastId, 'تم تحديث الطلب / الطلبات بنجاح')
    } else toastDone(toastId, 'تم حذف الطلب / الطلبات بنجاح')

    return {
      success: true
    }
  } catch (error: any) {
    handleError(error)
    console.log(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
