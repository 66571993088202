import cacheApi from '@/core/cacheApi'

import { useLanguage } from '@/composables/language'
import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'
import type { Attribute } from '@/types/attribute'
import { handleError } from '@/helpers/handleError'

const { mainLang } = useLanguage()
export async function getAllAttributes(): Promise<ApiResponse> {
  try {
    const storedApiValue = localStorage.getItem('newApiSwitchValue')
    const isNewAPI =
      storedApiValue !== null ? JSON.parse(storedApiValue) : import.meta.env.VITE_NEW_API === 'true'
    const url = isNewAPI
      ? `/wp-json/treesal/v2/products/attributes`
      : `/wp-json/wc/v3/products/attributes?lang=${mainLang}`
    const { data } = await cacheApi.get(url)
    return {
      success: true,
      data: data.map((attribute: Attribute) => ({
        ...attribute
      }))
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
