import api from '@/core/api'
import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'
import { handleError } from '@/helpers/handleError'
import { toast } from 'vue3-toastify'
import { toastDone } from '../util/toastHelper'

export async function deleteOrder(id: number): Promise<ApiResponse> {
  try {
    const toastId = toast.loading('جاري حذف الطلب')
    const { status: _status } = await api.delete(`/wp-json/wc/v3/orders/${id}?force=true`)

    toastDone(toastId, 'تم حذف الطلب بنجاح')

    return {
      success: true
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
