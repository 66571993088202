import { toast, type Id } from 'vue3-toastify'
import { toastError } from '@/api/util/toastHelper'
export const handleError = (error: any, toastId?: Id) => {
  if (error?.data?.status == 403) {
    if (toastId) toastError(toastId, ' غير مسموح لك الوصول لهذه البيانات ')
    toast.error(' غير مسموح لك الوصول لهذه البيانات ')
  } else {
    if (error?.message) {
      if (toastId) toastError(toastId, error?.message)
      toast.error(error?.message)
    } else {
      if (toastId) toastError(toastId, 'حدث خطأ في العملية')
      toast.error('حدث خطأ في العملية')
    }
  }
}
