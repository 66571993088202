import cacheApi from '@/core/cacheApi'
import { handleError } from '@/helpers/handleError'

import type { ApiResponse, ApiErrorResponse } from '@/types/apiResponse'

export async function getProductById(id: number | string): Promise<ApiResponse> {
  try {
    const storedApiValue = localStorage.getItem('newApiSwitchValue')
    const isNewAPI =
      storedApiValue !== null ? JSON.parse(storedApiValue) : import.meta.env.VITE_NEW_API === 'true'
    const url = isNewAPI ? `/wp-json/treesal/v2/products/${id}` : `/wp-json/wc/v3/products/${id}`
    const { data, status } = await cacheApi.get(url)

    if (status !== 200) {
      return {
        success: false
      }
    }
    return {
      success: true,
      data
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
