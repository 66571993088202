import cacheApi from '@/core/cacheApi'
import { handleError } from '@/helpers/handleError'
import type { ApiErrorResponse, ApiResponse } from '@/types/apiResponse'
export async function getSearchProducts(
  { search }: any,
  showVariations?: boolean
): Promise<ApiResponse> {
  try {
    const { data } = await cacheApi.get(`/wp-json/treesal/v1/internal/smart-search`, {
      params: {
        show_hidden: true,
        variable: true,
        query: search,
        show_variations: showVariations || null
      }
    })
    return {
      success: true,
      data: data
    }
  } catch (error: any) {
    handleError(error)
    return {
      success: false,
      error: error as ApiErrorResponse
    }
  }
}
