import { defineStore } from 'pinia'
import { formatProduct } from '@/helpers/formatProduct'

import { useAttributesStore } from './attributes'
import { updateProduct } from '@/api/products/updateProduct'
import { useProductsStore } from '@/stores/products'
import type { Product } from '@/types/product'
import type { Attribute } from '@/types/attribute'
import type { Image } from '@/types/image'
import type { Option } from '@/types/option'
import { useLanguage } from '@/composables/language'
import { getVariations } from '@/helpers/formatProduct'

const { isTreesalTwoLang } = useLanguage()
interface ProductState {
  product: Product | any
  secondLangProduct: Product | any
  attributesWithFullOptions: Attribute[]

  allVariations: any
  originalProductId: number
  originalProductLang: string | any
  selectedAttributes: any
}

export const useProductStore = defineStore('product', {
  state: (): ProductState => ({
    product: null,
    secondLangProduct: undefined,
    attributesWithFullOptions: [],
    originalProductId: 0,
    selectedAttributes: {},
    allVariations: [],
    originalProductLang: undefined
  }),
  actions: {
    async deleteImage(id: number) {
      const index = this.product?.images.findIndex((item: any) => item.id === id)!
      if (index === -1) {
        return {
          success: false
        }
      }
      this.product?.images.splice(index, 1)

      return {
        success: true
      }
    },

    saveSelectedImages(images: Image[]) {
      images.forEach((image) => {
        const alreadyExist = this.product?.images.some((item: any) => item.id === image.id)
        if (!alreadyExist) {
          this.product!.images = [...this.product!.images, image]
        }
      })
    },

    async productAttributesWithfullOptions() {
      if (!this.product) {
        return []
      }
      const attributeStore = useAttributesStore()
      await attributeStore.getAllAttributesOptions()
      while (attributeStore.optionsLoading) {
        await new Promise((resolve) => setTimeout(resolve, 100)) // Wait 100ms before checking again
      }
      // get options for each attribute
      const promiseList = this.product.allAttributes.map(async (attribute: Attribute) => {
        const attrIndex = attributeStore.attributes.findIndex(
          (attr) => attr.id + '' === attribute.id + ''
        )
        return attributeStore.attributes[attrIndex].options
      })

      // wait for promises
      const options: Option[][] = []
      for await (const option of promiseList) {
        option.forEach((opt: Option) => {
          if (!isTreesalTwoLang)
            if (opt.translations_names[this.originalProductLang])
              opt.name = opt.translations_names[this.originalProductLang]
        })
        options.push(option)
      }

      // format  options for each attribute
      const selectedAttributes: Attribute[] = []
      this.product.allAttributes.forEach((attr: Attribute, i: number) => {
        const selectedOptions: Option[] = []

        // if the attribute has no options
        // all its options marked selected: false

        if (!attr.options?.length) {
          options[i].forEach((O) => {
            if (!isTreesalTwoLang)
              if (O.translations_names[this.originalProductLang])
                O.name = O.translations_names[this.originalProductLang]
            selectedOptions.push({ ...O, selected: false })
          })
        } else {
          const attributOptions = options[i]
          attributOptions?.forEach((option) => {
            // current attribute options
            // onUpdateSingleLang
            // should return this line to this  const checkExist = attr.options?.find((item: any) => item.id == option.id)
            const checkExist = attr.options?.find((item: any) => {
              if (isTreesalTwoLang) return item.id == option.id
              else return item.name == option.name
            })
            if (!checkExist) {
              selectedOptions.push({ ...option, selected: false })
            } else {
              selectedOptions.push({ ...option, selected: true })
            }
          })
        }
        selectedAttributes.push({
          ...attr,
          options: selectedOptions
        })
      })
      this.attributesWithFullOptions = [...selectedAttributes]
    },

    async addProductAttribute(name: string) {
      const attributeStore = useAttributesStore()
      const attr = attributeStore.attributes.find((attr) => attr.name === name)
      if (!attr) {
        return {
          success: false
        }
      }
      this.product?.attributes.push({ ...attr })
      return {
        success: true
      }
    },
    async addProductAttributeById(selectedAttribute: SelectedAttribute) {
      const id = selectedAttribute.id
      const attributeStore = useAttributesStore()
      const attrIndex = this.product?.allAttributes.findIndex(
        (attr: Attribute) => attr.id + '' === id + ''
      )

      const newAttribute = attributeStore.attributes.find((attr) => attr.id + '' === id + '')
      if (!newAttribute) {
        return {
          success: false,
          message: 'Attribute not found'
        }
      }
      if (attrIndex !== -1) {
        this.product.allAttributes.splice(attrIndex, 1, {
          ...newAttribute,
          options: selectedAttribute.selectedOptions,
          variation: selectedAttribute.variation
        })
        if (selectedAttribute.variation)
          this.product.attributes.splice(attrIndex, 1, {
            ...newAttribute,
            options: selectedAttribute.selectedOptions,
            variation: selectedAttribute.variation
          })
      } else {
        this.product?.allAttributes.push({
          ...newAttribute,
          options: selectedAttribute.selectedOptions,
          variation: selectedAttribute.variation
        })
        if (selectedAttribute.variation)
          this.product.attributes.push({
            ...newAttribute,
            options: selectedAttribute.selectedOptions,
            variation: selectedAttribute.variation
          })
      }

      this.allVariations = getVariations(
        this.product.attributes.filter((attr: Attribute) => attr.variation),
        this.product.variations
      )
      await this.productAttributesWithfullOptions()

      return {
        success: true,
        message: 'Attribute updated successfully'
      }
    },

    async removeAttribute(id: number) {
      const productsStore = useProductsStore()
      const newAttributes = this.product?.allAttributes?.filter((item: any) => item.id !== id)
      const newAttributesFormatted = newAttributes.map((attr: Attribute) => ({
        id: attr.id,
        name: attr.name,
        position: 0,
        visible: true,
        variation: attr.variation,
        options: [
          ...attr.options!.map((item) => {
            return item.name ? item.name : item
          })
        ]
      }))

      if (!newAttributesFormatted) {
        return {
          success: false
        }
      }
      const defaultAtt = this.product.default_attributes.filter((att: any) => att.id != id)
      const { data, success } = await updateProduct(
        { attributes: newAttributesFormatted, default_attributes: defaultAtt },
        this.originalProductId
      )

      if (!success) {
        return {
          success: false
        }
      }
      this.product = formatProduct(data)
      if (productsStore.products.length) {
        const index = productsStore.products.findIndex((product) => product.id === this.product.id)
        if (index > -1) productsStore.products.splice(index, 1, { ...this.product })
        else productsStore.products.splice(0, 0, { ...this.product })
      }
      this.productAttributesWithfullOptions()
      this.allVariations = getVariations(this.product.attributes, this.product.variations)
      return {
        success: true
      }
    }
  }
})

export interface SelectedAttribute extends Omit<Attribute, 'options'> {
  selectedOptions: Option[] | undefined
  restOptions: Option[] | undefined
}
